<template>
    <transition :name="transition">
    <div class="dBox" v-show="data?.show" :tag="data?.TAG" :style="_boxStyle()">
         <template v-if="data">
            <div class="dQuestion" qtype="descriptive" :class="{
              required:false,
              noAsterisk:($parent.project.setup?.display_asterisk == 0)
            }">
                <div class="body">
                    <div class="question" v-if="(data.setup?.layout && data.setup?.layout > 1)">
                         <div class="layout" :type="data.setup?.layout" :style="{'--layout-img-width':data.setup.layout_img.width,'--layout-img-height':data.setup.layout_img.height}">
                            <div class="section img"><img :src="data.setup.layout_img.src"/></div>
                            <div class="section txt" v-html="_pipeText(data.question)"></div>
                         </div>
                    </div>
                    <div class="question" v-else  v-html="_pipeText(data.question)"></div>
                    <qfooter v-show="showFooter" ref="footer"></qfooter>
                    <!-- <qfooter ref="footer"></qfooter> -->
                </div>
            </div>
         </template>
    </div>
    </transition>
</template>
<script>
import qtypeFunctions from '@/js/qtypeFunctions'
import comp from './comp.js'
import qfooter from '@/components/qfooter'

export default {
    components: {qfooter},
    data(){
        return {
            data : null,
            showFooter : true,
        }
    },
    mixins : [qtypeFunctions,comp],
    props : ["item"],
    mounted(){
        this._initQuestion()
    },
    methods:{
        _initQuestion(){
            this.data = this.item
            // this.data.setup['layout'] = 2
            // this.data.setup['layout_img'] = {
            //     src : "https://express.splitsecondsurveys.co.uk/ide/gallery/ZjDvT51h1owGe2eqZ502T4MS8BOYwa8EBVyvinh0.png",
            //     width : "40vmin",
            //     height : "40vmin",
            // }
            // this.data.setup['btn_delay'] = 6
            this.data['form'] = {
                input : ""
            }
            this.data['response'] = this._initData()
        },
        _initData(){
            let q = this.data
            let data = {}
            let obj = {
                TAG : q.TAG,
                VAR : q.VAR,
                desc : "",
                text : "",
                value : "",
                ref : null
            }
            data[q.TAG] = obj
            return data
        },
        _setAns(){
            return new Promise((resolve,reject)=>{
                try{
                    this.data['response'] = this._initData()
                    this.data.response[this.data.TAG]["text"] = "descriptive"
                    this.data.response[this.data.TAG]["value"] = "descriptive"              
                    resolve()
                }catch(e){
                    reject()
                }
            })
        },
        _required(){
            return new Promise((resolve)=>{
                    resolve()
            })
        },
        _skiplogic(){
            return new Promise((resolve,reject)=>{
                try{
                    resolve()
                }catch(e){
                    reject()
                }
            })
        },    
    }
}
</script>
