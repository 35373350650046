<template>
    <Teleport to="body">
    <div class="videoPlayerModal" v-show="setup.show" :style = "{ '--videoWidth' : data?.setup.layout_video.width, '--videoHeight': data?.setup.layout_video.height}">
            <div class="content">
                <div class="spinner" v-if="isLoading"></div>
                <video ref="videoElem" :src="data?.setup.layout_video.src"  @canplay="_onCanPlay()" @playing="_onCanPlay()" @stalled="()=>{isLoading = true}" @waiting="()=>{isLoading = true}"
                @loadeddata="_onLoadedData()" ></video>
            </div>
    </div>
    </Teleport>
</template>
<script>
export default {
    data(){
        return {
            setup : {
                show : false,
            },
            isLoading:false,
            loadingTimeout:null,
            
        };
    },
    emits: ['video:ended'],
    props : {
        data : {
            required :true,
            default : null
        }
    },
    methods : {
        _showModal(){
            let loop = this.data.setup.layout_video.loop
            this.$refs['videoElem'].play()
            this.setup.show = true
            this.isLoading =false
            this.loadingTimeout= setTimeout(() =>{
                this.isLoading = true
           },100);
            
            this.$refs['videoElem'].onended = ()=>{
            loop -= 1
            if (loop >= 0){
                this.$refs['videoElem'].currentTime = 0
                this.$refs['videoElem'].play()
                
            }else{
                this.setup.show = false
                this.$emit("video:ended")
            }

           }
            
            
        },
        _hideModal(){
            this.setup.show = false
            clearTimeout(this.loadingTimeout)
        },
        _onCanPlay(){
            clearTimeout(this.loadingTimeout)
            this.isLoading = false
        },
        _onLoadedData(){
            console.log("mo load siya")

        }
    },
    mounted : ()=>{
        
    }
}


</script>
<style scoped>
.videoPlayerModal{
    height: 100%;
    width: 100%;
    background:rgba(0,0,0,.9);
    position: fixed;
    z-index: 900;
    top:0px;
    left:0px;
    display:flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    touch-action: none;
}
.videoPlayerModal .content{
    position: relative;
    width: 70vw;
    height: auto;
    max-width:100%;
    max-height: 90vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

}
.videoPlayerModal .content video{
    object-fit: contain;
    width: var(--videoWidth,95vw); 
    height: var(--videoHeight,95vh); 
    max-width: 95vw;
    max-height: 95vh;
    object-fit: contain; 
    border: none;
    display: block;
    justify-content: center;
}
.videoPlayerModal .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-top: 5px solid white;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}
@keyframes spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
</style>