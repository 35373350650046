<template>
    <div class="dInput" :class="{hasError:hasError}">
        <div class="prependTxt" v-if="prependText" v-html="prependText"></div>
        <!-- <input v-if="validate.type == 2" type="text" :style="style" :name="name" :placeholder="placeholder" v-model="_value" autocomplete="off" :disabled="disabled" @input="_numberOnly" :maxlength="((typeof(validate) != 'undefined') && (validate.max != 0)) ? validate?.max : null"/> -->
        <input :maxlength="validate?.max.toString().length" v-if="validate.type == 2" type="text" :style="style" :name="name" :placeholder="placeholder" v-model="_value" autocomplete="off" :disabled="disabled" @input="_numberOnly"/>
        <input v-else-if="validate.type == 3" type="text" :style="style" :name="name" :placeholder="placeholder" v-model="_value" autocomplete="off" :disabled="disabled" @input="_decimalOnly"/>
        <div v-else-if="validate.type == 4 && validate.filter_domain != null" class="input-span-wrapper">
            <input v-if="validate.type == 4 && validate.filter_domain != null" type="text" :name="name" :style="style" :placeholder="placeholder" v-model="_value" autocomplete="off" :disabled="disabled" :maxlength="((typeof(validate) != 'undefined') && (validate.max != 0)) ? validate?.max : null" @keyup="_onInput($event)" />
            <span v-if="validate.type == 4 && validate.filter_domain != null">{{ validate.filter_domain }}</span>
        </div>

        <!-- <input v-else-if="validate.type == 3" type="text" :style="style" :name="name" :placeholder="placeholder" v-model="_value" autocomplete="off" :disabled="disabled" @input="_decimalOnly" :maxlength="((typeof(validate) != 'undefined') && (validate.max != 0)) ? validate?.max : null"/> -->
        <flat-pickr class="dDatepicker" v-else-if="validate.type == 5" v-model="_value" :config="datepickerConfig"></flat-pickr>
        <flat-pickr class="dDatepicker" v-else-if="validate.type == 6" v-model="_value" :config="timepickerConfig"></flat-pickr>
        <flat-pickr class="dDatepicker" v-else-if="validate.type == 7" v-model="_value" :config="datetimepickerConfig"></flat-pickr>
        <input v-else-if="validate.type == 8" type="text" :name="name" :style="style" :placeholder="placeholder" v-model="_value" autocomplete="off" :disabled="disabled" :maxlength="((typeof(validate) != 'undefined') && (validate.max != 0)) ? validate?.max : null" @keyup="_onInputAlpha($event)"/>
        <input v-else-if="validate.type == 9" type="text" :name="name" :style="style" :placeholder="placeholder" v-model="_value" autocomplete="off" :disabled="disabled" :maxlength="((typeof(validate) != 'undefined') && (validate.max != 0)) ? validate?.max : null" @keyup="_onInputAlphaNum($event)"/>
        <input v-else type="text" :name="name" :style="style" :placeholder="placeholder" v-model="_value" autocomplete="off" :disabled="disabled" :maxlength="((typeof(validate) != 'undefined') && (validate.max != 0)) ? validate?.max : null" @keyup="_onInput($event)"/>

        <div class="appendTxt" v-if="appendText" v-html="appendText"></div>
   </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import helper from '@/js/helper'
export default({
   data(){
       return {
           hasError : false,
           enableRT : 1,
           startTime : null,
           overallRT : null,
           splitCharacters : [],
           datepickerConfig : {
                wrap: true,
                altFormat: 'j F Y',
                altInput: true,
                dateFormat: 'd/m/Y',
                disableMobile: true
           },
           timepickerConfig : {
                wrap: true,
                altFormat: 'h:i K',
                altInput: true,
                disableMobile: true,
                enableTime: true,
                noCalendar: true,
                dateFormat: "h:i K",
                time_24hr: false
           },
           datetimepickerConfig : {
                wrap: true,
                altFormat: 'j F Y h:i K',
                altInput: true,
                dateFormat: 'd/m/Y H:i',
                disableMobile: true,
                enableTime: true
           },
           style : {},
       }
   },
   props : ["name","value","placeholder","disabled","validate","RTs",'connection','domStyle','appendText','prependText'],
   emits: ['update:value','update:RTs'],
   mixins : [helper],
   components: {
        flatPickr
   },
   mounted(){
       this._genDomStyle()
   },
   watch:{
       style(){
           this._genDomStyle()
       }
   },
   computed:{
       _value:{
           get(){
               return this.value
           },
           set(val) {

            this.$emit("update:value", val); // Emit the updated value
        }
       },
   },
   methods:{
       _genDomStyle(){
           this.$nextTick(()=>{
                if(typeof(this.domStyle) != 'undefined'){
                    if(typeof(this.domStyle['max-width']) != 'undefined'){
                        this.style['max-width'] = this.domStyle['max-width']
                        //this.style['min-width'] = this.domStyle['max-width']
                    }
                }
           })
       },
      _validate(required = 1){
        let res = {
            status : true,
            message : null
        }

        if(!this.validate){
            res.status = true
            return res
        }

        
        let input = (this.value != null) ? this.value.trim() : null
        this.hasError = false

        if((required == 1) && (input == "")){
            this.hasError = true
            //throw this.validate.errorMsg
            res.status = false
            res.message = this.validate.errorMsg
            return res
        }
        if(input != ""){
            let Project = null
            window.dispatchEvent(new CustomEvent('getSurveyInfo', {
                detail: {
                    callback : (res) => {
                       // console.log(res)
                        Project = res
                    }
                }
            }))

            console.log("Project", Project)

            if(this.validate.type == 1 || this.validate.type == 8 || this.validate.type == 9){
                /*if (!(input.length >= this.validate.min && input.length <= this.validate.max)) {
                    this.hasError = true
                    res.status = false
                    res.message = this.validate.errorMsg
                    return res
                }*/
                if(this.validate.min && this.validate.min > input.length){
                    this.hasError = true
                    res.status = false
                    res.message = this.validate.errorMsg
                    if(Project.setup.messages.input1_warning_min){
                        res.message = this._textReplacer(Project.setup.messages.input1_warning_min,{
                            "@min" : this.validate.min
                        })
                    }
                    return res                   
                }
                if(this.validate.max && this.validate.max < input.length){
                    this.hasError = true
                    res.status = false
                    res.message = this.validate.errorMsg
                    if(Project.setup.messages.input1_warning_max){
                        res.message = this._textReplacer(Project.setup.messages.input1_warning_max,{
                            "@max" : this.validate.max
                        })
                    }
                    return res                   
                }
            }
            if(this.validate.type == 2 || this.validate.type == 3){
                const numericValue = parseFloat(input);
                if (this.validate.min && (numericValue < this.validate.min)) {
                    this.hasError = true
                    res.status = false
                    res.message = this.validate.errorMsg
                    if(Project.setup.messages.input2_warning_min){
                        res.message = this._textReplacer(Project.setup.messages.input2_warning_min,{
                            "@min" : this.validate.min
                        })
                    }
                    return res  
                }
                if (this.validate.max && (numericValue > this.validate.max)) {
                    this.hasError = true
                    res.status = false
                    res.message = this.validate.errorMsg
                    if(Project.setup.messages.input2_warning_max){
                        res.message = this._textReplacer(Project.setup.messages.input2_warning_max,{
                            "@max" : this.validate.max
                        })
                    }
                    return res  
                }
            }
    

            // if(this.validate.type == 2 || this.validate.type == 3){
            //     if (!(parseFloat(input) >= parseFloat(this.validate.min) && parseFloat(input) <= parseFloat(this.validate.max))) {
            //         this.hasError = true
            //         res.status = false
            //         res.message = this.validate.errorMsg
            //         return res                         
            //     }
            // } 
            if (this.validate.type == 4 && this.validate.filter_domain == null) {
                // Perform the email validation
                const isEmailValid = this._validateEmail(input);

                if (!isEmailValid) {
                    this.hasError = true;
                    res.status = false;
                    res.message = this.validate.errorMsg;
                    if(Project.setup.messages.input4_warning_invalidemail){
                        res.message = Project.setup.messages.input4_warning_invalidemail
                    }
                    return res;
                }


            }

            if (this.validate.type == 4 && this.validate.filter_domain != null) {
                // Perform the email validation
                const isEmailValid = this._validateEmail(input);
                if (isEmailValid || input.includes('@')) {
                    this.hasError = true;
                    res.status = false;
                    res.message = this.validate.errorMsg;
                    return res;
                }
            }

            if(this.validate.type == 5 || this.validate.type == 6 || this.validate.type == 7){
                if(!input){
                    this.hasError = true
                    res.status = false
                    res.message = this.validate.errorMsg
                    return res                         
                }
            }
            if(this.validate.type == 8){
                const isLettersOnly = /^[a-zA-Z]+$/.test(input);
                if (!isLettersOnly) {
                    this.hasError = true
                    res.status = false
                    res.message = this.validate.errorMsg
                    if(Project.setup.messages.input8_warning_letters){
                        res.message = Project.setup.messages.input8_warning_letters
                    }
                    return res
                }               
            }
            if(this.validate.type == 9){
                const isAlphanumeric = /^[a-zA-Z0-9]+$/.test(input);
                if (!isAlphanumeric) {
                    this.hasError = true
                    res.status = false
                    res.message = this.validate.errorMsg
                    if(Project.setup.messages.input9_warning_alphanumeric){
                        res.message = Project.setup.messages.input9_warning_alphanumeric
                    }
                    return res
                }               
            }


        }
        return res
      },
    //_onlyNumbers(evt,obj = {}){
        //   console.log(evt.data, this._value)
        //     let decimal = false
        //     if(typeof(obj.decimal) != "undefined"){
        //         decimal = obj.decimal
        //     }
            // evt = (evt) ? evt : window.event;
            // var charCode = (evt.which) ? evt.which : evt.keyCode;

            // if(!decimal){
            //     if (charCode === 46 || charCode === 62) {
            //         evt.preventDefault();
            //     }
            // }
            // if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            //   evt.preventDefault();
            // } else {
            //   return true;
            // }
            
           
    //   },
        _numberOnly(e){ 
            

            e.target.value =  e.target.value.replace(/[^0-9]+/gi, '')
           // e.target.value = 2
            //console.log( e.target.value)
            this.$emit("update:value", e.target.value)

       },
        _decimalOnly(e){
            if(e.target.value.match(/^[0-9]+\.?([0-9]+)?$/) === null){
                e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                this.$emit("update:value", e.target.value)
            }   
       },
      _onInput(evt){
          if(this.startTime == null){
              this.startTime = new Date().getTime()
          }
          evt = (evt) ? evt : window.event;
          var charCode = (evt.which) ? evt.which : evt.keyCode;
           let ch = this.value.split(",")
           ch.forEach((c,k)=>{
              let temp = {
                  text : c,
                  rt : 0
              }
              if(typeof(this.splitCharacters[k]) == "undefined"){
                 this.splitCharacters.push(temp)
              }else{
                    let target = this.splitCharacters[k]
                    if(target.text != c){
                        this.splitCharacters[k].text = c
                        if(charCode != 8){
                            this.splitCharacters[k].rt = new Date().getTime() - this.startTime
                        }
                    }
              }
           })
           this.splitCharacters = this.splitCharacters.slice(0, ch.length)
           let RTs = ""
           this.splitCharacters.forEach((v)=>{
               RTs +=v.rt+","
           })
           RTs = RTs.substring(0, RTs.length - 1)
           this.$emit("update:RTs", RTs)
      },
      _onInputAlpha(e){
        if(e.target.value.match(/^[a-zA-Z]+\.?([a-zA-Z]+)?$/) === null){
                e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                this.$emit("update:value", e.target.value)
            } 

      },
      _onInputAlphaNum(e){
        if(e.target.value.match(/^[a-zA-Z0-9]+\.?([a-zA-Z0-9]+)?$/) === null){
                e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                this.$emit("update:value", e.target.value)
            } 

      }
   }

})
</script>
<style scoped>
.input-span-wrapper {
    display: flex;
    align-items: center;
}

.input-span-wrapper input {
    font-size: 2.5vmin; /* Converted from 1.4rem to vmin */
    padding: 1.2vmin; /* Converted from 12px to vmin */
    border-top-left-radius: 1vmin; /* Converted from 10px to vmin */
    border-bottom-left-radius: 1vmin; /* Converted from 10px to vmin */
    border-top-right-radius: 0; /* No border-radius on right side */
    border-bottom-right-radius: 0;
    border: 0.1vmin solid rgba(126, 126, 126, 0.253); /* Converted border width from 1px to vmin */
}

.input-span-wrapper span {
    font-size: 2.5vmin; /* Converted from 1.4rem to vmin */
    background-color: #D3D3D3; /* Light gray background */
    padding: 1.2vmin; /* Converted from 12px to vmin */
    border-top-right-radius: 1vmin; /* Converted from 10px to vmin */
    border-bottom-right-radius: 1vmin; /* Converted from 10px to vmin */
    border: 0.1vmin solid rgba(126, 126, 126, 0.76); /* Converted border width from 1px to vmin */
}

</style>
